<div class="wrapper">
    <div class="row">
        <div class="col-12 p-2">
            <div class="hero-container">
                <img *ngIf="!isMobile" class="hero-image" src="assets/images/newsletter_banner_desktop.png" alt="PLUS Dagje Uit" />
                <img *ngIf="isMobile" class="hero-image mt-2" src="assets/images/newsletter_banner_mobile.png" alt="PLUS Dagje Uit" />
            </div>
            <div class="discount-container">
                <div class="discount-preamb">
                    <span>{{'ATTRACTION_CARD.up_to' | translate}}</span>
                </div>
                <div class="discount-amount">
                    <span>{{highestDiscountPercentage}}%</span>
                </div>
                <div class="discount-postamb">
                    <span>{{'ATTRACTION_CARD.discount' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-2">
        <div class="row mb-2">
            <div class="col-12">
                <h2>Onze Halloween herfstvakantie toppers!</h2>
                <h2 class="off-color">Verzilver nu je korting voor een geweldige herfstvakantie met unieke dagjes uit.</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="mb-2">
                    De herfstvakantie staat voor de deur, en dat betekent dé perfecte tijd om eropuit te gaan! Maak
                    gebruik van jouw gespaarde korting voor deze speciale griezeldeals en beleef onvergetelijke
                    momenten met familie en vrienden. Grijp deze tijdelijke actie en geniet van extra voordeel op
                    onze populairste uitjes. Durf jij het aan?
                </p>
                <h4>Jouw gespaarde vouchers zijn nog in te wisselen tot en met 5 januari 2025!</h4>
            </div>
        </div>
        <div class="row mt-4 mb-2">
            <div class="{{isMobile ? 'col-12': 'col-4'}} mb-4 align-content-center" *ngFor="let merchant of merchants">
                <qup-attraction-card [product]="merchant"></qup-attraction-card>
            </div>
        </div>
        <div class="row">
            <div class="row-12 mb-2">
                <p>Toch op zoek naar een ander uitje? Ontdek hier alle andere leuke uitjes welke je deze herfstvakantie kunt bezoeken!</p>
            </div>
            <div class="col-12 align-content-center mt-2">
                <div class="see-trips" (click)="navigateToTrips()">
                    <i class="material-symbols-outlined">attractions</i>
                    <span> Bekijk de overige Dagjes Uit</span>
                </div>
            </div>
        </div>
    </div>
</div>